@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.transaction-card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 2px solid rgba(242, 192, 222, 1);
}

.transaction-card p {
  margin: 5px 0;
}

/* Login Page */
.login {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: "white";
}

.login .login-container {
  background-color: white;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.login .login-logo {
  position: relative;
  z-index: 2;
  width: 250px;
}

.login-title {
  position: relative;
  z-index: 2;
  color: rgba(20, 19, 61, 1);
  font-size: 28px;
  text-align: right;
  margin-bottom: -1rem;
}

.login .login-form {
  display: flex;
  flex-direction: column;
}

.login .login-button {
  margin-top: 1rem;
  background-color: rgba(255, 82, 179, 1);
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
}

.login .login-button:hover {
  background-color: rgba(205, 14, 121, 1);
}

.login .test-login-container {
  padding: 1.5rem;
  border-radius: 20px;
  background-image: radial-gradient(circle at 50% 50%, #ff52b3, #fdcde7 79%);
}

.test-login-box {
  background-image: radial-gradient(circle at 50% 50%, #ff52b3, #fdcde7 79%);
}

.test-login-input {
  padding: 1rem;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.6);
}

.test-login-input .MuiInputBase-formControl {
  border-radius: 30px;
  background-color: #FFF;
}

.Ellipse-1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(40px);
  background-color: #fff;
}

.f1 {
  position: relative;
  margin-bottom: 1.5rem;
}

.login .f2 {
  width: 100%;
  max-width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem auto;
  border-radius: 30px;
  background-color: rgba(255, 82, 179, 1);
}

.login .login-container {
  background-color: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

@media (max-width: 600px) {
  .login {
    background: radial-gradient(circle at 50% 50%, #ff52b3, #fdcde7 79%);
  }

  .login .login-container {
    background: none;
    box-shadow: none;
  }

  .login .test-login-container {
    background: none;
  }
}

@media (max-width: 600px) {

}
